/**
 * src/js/main.js
 *
 * main javascript file
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package dreamtool
 */

function APP () {

	var self = this;

	self.debug = false;

	self.useAjaxLoading = false;

	self.flickityOptions = {
		bgLazyLoad: 1,
		imagesLoaded: true,
		pageDots: false,
		wrapAround: true,
		arrowShape: 'M 25,50 75,0 75,20 45,50 75,80 75,100 Z'
	};

	self.kenBurnsInterval = 8000;

	this.init = function() {
		
		if (this.debug) {
			console.log('APP::init');
			this.debugInit ();
		}
		
		this.pageInit();
		this.lazyLoading ();
		this.initSlider ();
		this.initModal ();
		this.teaserClick ();
		// this.initAppear ();
        this.initMovieOverlayHideAndReveal ();

		imagesLoaded (
			document.querySelectorAll ('.slideshow img'),
			this.slideshow
		);

        // Scrollreveal
        // window.onload = function () {
        //     window.sr = ScrollReveal ();
        //     sr.reveal (document.querySelectorAll ('.js-appear'));
        // };

		if (this.useAjaxLoading) {
			this.initAjaxLoading ();
		}

	};



    this.initMovieOverlayHideAndReveal = function () {

        var container = document.querySelector('.movie__overlay');
        var videoButton = document.querySelector('.button.modal-trigger');
		if (!container || !videoButton) {
			return;
		}

        container.addEventListener ('mouseenter', show);
        container.addEventListener ('mouseleave', hide);
        window.setTimeout (hide, 3000);
            
        function hide () {
            var btnRect = videoButton.getBoundingClientRect ();
            var cntRect = container.getBoundingClientRect ();
            videoButton.style.transform = 'translate3d(' + (btnRect.left - cntRect.left) + 'px, ' + (cntRect.bottom - btnRect.bottom) + 'px, 0)';
            container.classList.add ('movie__overlay--is-hidden');
        }

        function show () {
            videoButton.style.transform = 'translate3d(0, 0, 0)';
            container.classList.remove ('movie__overlay--is-hidden');
        }
    };

	this.pageInit = function() {

		if (this.debug) {
			console.log('APP::pageInit');
		}

		document.body.classList.add('page-has-loaded');
		this.main();
	};

	this.teaserClick = function () {
		var teasers = document.querySelectorAll ('.movie-teaser,.member-teaser');
		for (var i = 0; i < teasers.length; i++) {

			var teaser = teasers[i];

			if (teaser.querySelector ('.button')) {
				teaser.style.cursor = 'pointer';
				teaser.addEventListener ('click', function (ev) {
					ev.stopPropagation ();
					this.querySelector ('.button').click ();
					return false;
				});
			}
		}

		// Don't trigger modal when clicking on action-area buttons of member-teasers
		var buttons = document.querySelectorAll ('.sm-link');
		for (i = 0; i < buttons.length; i++) {
			buttons[i].addEventListener ('click', function (ev) {
				ev.stopPropagation ();
			});
		}
	};

	// Kenburns by http://www.cssmojo.com/ken-burns-effect/
	this.slideshow = function () {

		var container = document.querySelector ('.slideshow');
		if (!container) {
			return;
		}
		var images = container.querySelectorAll ('.slideshow__slide'),
			images_count = images.length,
			i = 1,
			overlay = document.querySelector ('.slideshow__overlay');
		;

		images[0].classList.add ('fx');
		window.setInterval (slideshowNext, self.kenBurnsInterval);

		function slideshowNext () {
			if (i == images_count) {
				i = 0;
			}
			images[i].classList.add ('fx');
			updateSlides ();
		}

		// function slideshowPrev () {
		// 	if (i == -1) {
		// 		i = images_count - 1;
		// 	}
		// 	images[i].classList.add ('fx');
		// 	updateSlides ();
		// }

		function updateSlides () {

			if (i === 0) {
				images[images_count - 2].classList.remove ('fx');
			}
			if (i === 1) {
				images[images_count - 1].classList.remove ('fx');
			}
			if (i > 1) {
				images[i - 2].classList.remove ('fx');
			}
			i++;
		}

	};

	this.initModal = function () {
		var triggers = document.querySelectorAll ('.modal-trigger');
		for (var i = 0; i < triggers.length; i++) {
			triggers[i].addEventListener ('click', function (ev) {
				ev.preventDefault ();

				var modal = document.createElement ('div');
				modal.className = 'modal';

				var modalWindow = document.createElement ('div');
				modalWindow.className = 'modal__window';
				modal.appendChild (modalWindow);

				var modalCloseButton = document.createElement ('button');
				modalCloseButton.className = 'modal__close';
				modalCloseButton.innerHTML = '';
				modalWindow.appendChild (modalCloseButton);

				modalCloseButton.addEventListener ('click', function () {
					self.closeModal ();
					// modal.addEventListener ('transitionend', function () {
					// 	document.body.removeChild (modal);
					// });
					// modal.classList.remove ('modal--is-visible');
				});

				modalContent = document.createElement ('div');
				modalContent.className = 'modal__content';
				modalContent.innerHTML = "Loading, please wait..";

				if (this.hasAttribute ('href')) {
					// Fetch content from href target by AJAX request
					var url = this.getAttribute ('href');
					var xhr = new XMLHttpRequest ();
					// Use the AJAX template (template id=9)
					xhr.open ('GET', url + '?tid=9');
					xhr.onload = function () {
						if (xhr.status >= 200 && xhr.status < 400) {
							modalContent.innerHTML = xhr.responseText;
						}
					}
					xhr.send ();
				}

				modalWindow.appendChild (modalContent);
				document.body.appendChild (modal);
				setTimeout(function() {
					// timeout makes the modal
					// pop up smooth, else it pops up without
					// transition... (dont know why though...)
					modal.classList.add ('modal--is-visible');
					modal.addEventListener ('transitionend', function () {
						self.bLazy.revalidate ();
					});
				}, 50);
			});
		}

		// console.log ("Adding Event Listener");
		// document.body.addEventListener ('click', self.closeModalOnClickOutside);

		document.body.addEventListener ('keyup', function (ev) {
			if (ev.keyCode == 27) {
				self.closeModal ();
			}
		});
	}



	this.closeModalOnClickOutside = function (ev) {

		// console.log (ev.target);
		insideModal = false;
		for (var node = ev.target; node != null; node = node.parentNode) {

			if (node.tagName == 'BODY') {
				break;
			}
			if (node.classList.contains ('modal__window')) {
				insideModal = true;
				break;
			}
		}

		console.log ('insideModal', insideModal);

		if (!insideModal) {
			self.closeModal ();
		}
	}



	this.closeModal = function () {
		var modals = document.querySelectorAll ('.modal');
		for (var i = 0; i < modals.length; i++) {
			var modal = modals[i];
			if (modal) {
				modal.addEventListener ('transitionend', function () {
					var modals = document.querySelectorAll ('.modal');
					for (var i = 0; i < modals.length; i++) {
						document.body.removeChild (modals[i]);
					}
				});
				modal.classList.remove ('modal--is-visible');
			}
		}
		// console.log ("Removing event listener");
		// document.body.removeEventListener ('click', self.closeModalOnClickOutside);
	}
	
	this.debugInit = function () {
		console.log ('Debug init');
		document.addEventListener ('keyup', function () {
			document.body.classList.remove ('debug-base-line-grid--is-visible');
		});
		document.addEventListener ('keydown', function (ev) {
			if (ev.keyCode == 16) {
				document.body.classList.add ('debug-base-line-grid--is-visible');
			}
		});
	};

	this.lazyLoading = function () {

		var lazyImages = document.querySelectorAll ('.lazy');
		for (var i = 0; i < lazyImages.length; i++) {
			lazyImages[i].style.visibility = 'visible';
			lazyImages[i].style.display = 'block';
		}


		// Lazy loadiong images
		this.bLazy = new Blazy({
			selector: '.lazy',
			successClass: 'lazy--loaded',
			errorClass: 'lazy--failed',
			error: function(el) {
			 	el.src = '/dist/img/noimage.svg';
			}
		});
	}

	this.main = function() {

		// this.initThrottleResize();

	};

	this.initAjaxLoading = function () {
		if (self.debug) {
			console.log ('initAjaxLoading');
		}

		var links = document.querySelectorAll ('.js-ajax-load');
		for (var i = 0; i < links.length; i++) {
			var link = links[i];
			link.addEventListener ('click', self.onAjaxLinkClicked);
		}
	}

	this.onAjaxLinkClicked = function (ev) {
		ev.preventDefault ();

		var url = this.getAttribute ('href');
		if (self.debug) {
			console.log ('Ajax-loading: ' + url);
		}

		var ajaxContainer = document.getElementById ('js-ajax-load-container');
		if (!ajaxContainer) {
			return;
		}


		var xhr = new XMLHttpRequest ();
		// Use the AJAX-Loading template (template id=10)
		xhr.open ('GET', url + '?tid=10');
		xhr.onload = function () {
			if (xhr.status >= 200 && xhr.status < 400) {
				// modalContent.innerHTML = xhr.responseText;
				ajaxContainer.innerHTML = xhr.responseText;
				document.body.addEventListener ('transitionend', function () {
					self.bLazy.revalidate ();
					self.initAjaxLoading ();
					self.initSlider ();
				});
				document.body.classList.remove ('ajax--is-loading');
			}
		}
		document.body.classList.add ('ajax--is-loading');
		xhr.send ();

		return false;
	}

	this.initSlider = function () {
		if (self.debug) {
			console.log ('APP::initSlider');
		}
		var slider = document.getElementById ('js-slider'); 
		if (!slider) {
			if (self.debug) {
				console.log ('APP::initSlider: No slider found, ...');
			}
			return;
		}
		var flkty = new Flickity (slider, self.flickityOptions);
	}
};

var app;

document.addEventListener('DOMContentLoaded', function() {

	app = new APP();
	app.init();

});

// Lazy-load feeds
window.addEventListener ('load', function () {

	var container = document.getElementById ('js-feeds-container');
	if (!container) {
		return;
	}

	var url = document.getElementById ('js-load-feeds-url').value;
	// Use the AJAX-Loading template (template id=10)
	var xhr = new XMLHttpRequest ();
	xhr.open ('GET', url + '?tid=10');
	xhr.onload = function () {
		if (xhr.status >= 200 && xhr.status < 400) {
			container.innerHTML = xhr.responseText;
			window.setTimeout (app.bLazy.revalidate, 1000);
		}
	}
	xhr.send ();
});
